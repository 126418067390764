import React, { Component } from 'react';

export default class Dots extends Component {
  render() {
    return (
      <svg
        className="dots"
        width="10"
        height="2"
        viewBox="0 0 10 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.999964 2.00005C1.55223 2.00005 1.99993 1.55235 1.99993 1.00009C1.99993 0.447821 1.55223 0.00012207 0.999964 0.00012207C0.447699 0.00012207 0 0.447821 0 1.00009C0 1.55235 0.447699 2.00005 0.999964 2.00005Z"
          fill="#8C8C8C"
        />
        <path
          d="M5.00009 1.99993C5.55235 1.99993 6.00005 1.55223 6.00005 0.999964C6.00005 0.447699 5.55235 0 5.00009 0C4.44782 0 4.00012 0.447699 4.00012 0.999964C4.00012 1.55223 4.44782 1.99993 5.00009 1.99993Z"
          fill="#8C8C8C"
        />
        <path
          d="M8.99948 1.99993C9.55174 1.99993 9.99944 1.55223 9.99944 0.999964C9.99944 0.447699 9.55174 0 8.99948 0C8.44721 0 7.99951 0.447699 7.99951 0.999964C7.99951 1.55223 8.44721 1.99993 8.99948 1.99993Z"
          fill="#8C8C8C"
        />
      </svg>
    );
  }
}
